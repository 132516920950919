<template>
	<div id="landOrder">
		<page-land-rent-order :is-shop="1"></page-land-rent-order>
	</div>
</template>

<script>
	import pageLandRentOrder from '@/components/layout/land/page-land-rent-order.vue'
	export default{
		components:{
			pageLandRentOrder
		}
	}
</script>

<style>
</style>
